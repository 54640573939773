.app-shrimmer {
  padding-top: 16vh;

  .table-det {

    .row-d {
      width: 94%;
      height: 40vw;
      margin: 0 3vw 3vh 3vw;
    }

    @keyframes shimmer {
      0% {
        background-position: -1000px 0;
      }

      100% {
        background-position: 1000px 0;
      }
    }

    .custom-shimmer {
      // background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%, #eeeeee 73%);
      background: linear-gradient(to right, #f7f7f7 8%, #f0f0f0 18%, #f7f7f7 33%, #f7f7f7 73%);
      background-size: 1000px 100%;
      animation: shimmer 2s infinite linear;
      border-radius: 10px;
    }
  }
}